import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { SearchOutlined } from '@ant-design/icons';
import { gsap } from 'gsap';

import { useUrlForm } from '../../hooks';
import { Autocomplete, Select, Input } from '../inputs';
import PROPERTY_TYPE from '../../constants/PROPERTY_TYPE.json';
import COMMUNES from '../../constants/CITIES.json';
import { Button } from '../../styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Form = styled.form`
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  @media(min-width: 768px){
    padding: 2rem 5%;
  }
`;

const FormInnerCont = styled.div` 
  margin-bottom: 1rem;
  padding: 0;
`;

const Label = styled.span`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export default ({ withFilters, id }) => {
  const [filter, setFilter] = useState(true);
  const { values, onChange, getUrl, setValues } = useUrlForm({
    propertyType: '',
    operation: '',
    commune: '',
    stringSearch: '',
    priceMin: '',
    priceMax: '',
    totalAreaFrom: '',
    totalAreaTo: '',    
    bedrooms: '',
    bathrooms: '',
    currency: '',    
  });

  const handleFilter = () => {
    setValues({
      priceMin: '',
      priceMax: '',
      totalAreaFrom: '',
      totalAreaTo: '',    
      bedrooms: '',
      bathrooms: '',
      currency: '',          
    });
    setFilter(!filter);
  }

  useEffect(() => {
    if (filter) {
      gsap.from("#filters", { opacity: 0, y: 10, duration: .5, ease: "back.out(1.7)" });
    }
  }, [filter])

  const uniqueRegions = Array.from(new Set(COMMUNES.map(val => val.state.name)));

  return (
    <Form
      id={id}
      onSubmit={(e) => e.preventDefault()}
      withFilters={withFilters}
    >
      <FormInnerCont first>
        <Row noGutters>
          <Col xs={12} md={2}>
            <Label>Tipo de Propiedad</Label>
            <Select
              id="propertyType"
              onChange={onChange}
              value={values.propertyType}
              default="Propiedad"
              options={PROPERTY_TYPE}
              primary
              capitalize
            />
          </Col>
          <Col xs={12} md={2}>
            <Label>Tipo de Operación</Label>
            <Select
              id="operation"
              onChange={onChange}        
              value={values.operation}          
              default="Operación"
              options={["Venta", "Arriendo"]}
              primary
              capitalize
            />
          </Col>
          <Col xs={12} md={2}>
            <Label>Comuna</Label>
            <Autocomplete
              id="commune"
              onSelect={onChange}
              selected={values.commune}
              options={COMMUNES.map(val => val.name)}
              placeholder="Todas"
            />
          </Col>
          <Col xs={12} md={2}>
            <Label>Región</Label>
            <Autocomplete
              id="region"
              onSelect={onChange}
              selected={values.region}
              options={uniqueRegions}
              placeholder="Todas"
            />
          </Col>      
          <Col xs={12} md={4}>
            <Label>Buscar</Label>
            <AniLink fade to={getUrl()} duration={.5}>
              <Button
                block
                primary
                type="submit"
                icon
                title="Buscar"
              >
                <span className="d-xs-block d-md-none">Buscar</span>
                <SearchOutlined className="d-none d-md-block" />
              </Button>
            </AniLink>
          </Col>
        </Row>
      </FormInnerCont>
      {filter && (
        <FormInnerCont first id="filters">
          <Row noGutters>
            <Col xs={12} md={2}>
              <Label>Dormitorios</Label>
              <Input
                id="bedrooms"
                onChange={onChange}
                value={values.bedrooms}
                placeholder="0"
                primary
                type="number"
                min={0}
                max={9}
              />
            </Col>
            <Col xs={12} md={2}>
              <Label>Baños</Label>
              <Input
                id="bathrooms"
                onChange={onChange}
                value={values.bathrooms}
                placeholder="0"
                primary
                type="number"
                min={0}
                max={9}
              />
            </Col>   
            <Col xs={12} md={2}>
              <Label>Precio desde</Label>
              <Input
                id="priceMin"
                onChange={onChange}
                value={values.priceMin}
                placeholder="0"
                primary
                type="number"
                min={0}
              />
            </Col>
            <Col xs={12} md={3}>
              <Label>Precio hasta</Label>
              <Input
                id="priceMax"
                onChange={onChange}
                value={values.priceMax}
                placeholder="50000"
                primary
                type="number"
                min={0}
              />
            </Col>        
            <Col xs={12} md={3}>
              <Label>Tipo de moneda</Label>
              <Select
                id="currency"
                onChange={onChange}
                value={values.currency}
                default="Moneda"
                options={["CLP", "UF"]}
                primary
                noAll
              />
            </Col>   
          </Row>
        </FormInnerCont>
      )}
    </Form>
  )
}

import React, { useContext } from 'react';
import context from '../../context';
import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { priceFormat, truncate } from '../../util';
import { Site, Surface, Parking, Bath, Rooms } from '../../icons';

const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const AniLinkCustom = styled(AniLink)`
  color: inherit !important;
  display: block;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 
  0px 1px 1px rgba(0, 0, 0, .12),
  0px 2px 2px rgba(0, 0, 0, .12),
  0px 4px 4px rgba(0, 0, 0, .12),
  0px 8px 8px rgba(0, 0, 0, .12),
  0px 16px 16px rgba(0, 0, 0, .12);
  transition: transform 0.3s ease; /* Agregamos una transición al efecto de zoom */
  &:hover {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, .12),
                0px 2px 2px rgba(0, 0, 0, .12),
                0px 4px 4px rgba(0, 0, 0, .12),
                0px 8px 8px rgba(0, 0, 0, .12),
                0px 16px 16px rgba(0, 0, 0, .12);
    transform: scale(1.1); /* Hacemos un zoom del 10% al hacer hover */
  }
`;

const Card = styled.div`
  width: 100%;
  border: 6px solid rgba(0, 0, 0, 0.1);
  min-height: 600px; /* Ajusta la altura según tus necesidades */
  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Image = styled.div`
  width: 100%;
  padding-top: 75%;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  border-radius: 6px;
`;

const InfoCont = styled.div`
  padding: 0 1rem; 
`;

const TitleCont = styled.div`
  padding-top: 1rem;
  font-size: .8rem;
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
  color: ${props => props.theme.primaryColor}; /* Usar el color primario definido en el tema */
  font-size: 1rem;
  justify-content: left; 
  font-weight: bold; /* Hacer el texto en negrita */
  .value {
    margin-left: 0.5rem;
  }
`;

const Price = styled.p`
  margin: 0;
  color: ${props => props.theme.primaryColor};
`;

const Code = styled.p`
  margin: 0;
`;

const CharsList = styled.ul`
  padding: 0;
  margin: 0;
  color: gray;
  font-size: .8rem;
  padding: 1rem 0;
  span {
    margin-left: .5rem;
  }
`;

const CharItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: left; /* Centrar el contenido horizontalmente */
  padding-bottom: 0.3rem;
  .value {
    margin-left: 0.5rem;
  }
`;

const CharItemUbi = styled.li`
  display: flex;
  align-items: center;
  border: 4px solid rgba(0, 0, 0, .1);
  border-radius: 6px;
  padding-bottom: 0.3rem;
  color: ${props => props.theme.primaryColor}; /* Usar el color primario definido en el tema */
  font-size: 1rem;
  justify-content: left; 
  font-weight: bold; /* Hacer el texto en negrita */
  .value {
    margin-left: 0.5rem;
  }
`;

const Boton = styled.a`
  text-decoration: none;
  color: #fff !important;
  background-color: ${props => props.theme.primaryColor};
  transition: 250ms ease;
  width: 150px;
  border: 1px solid white;
  height: 40px;
  border-radius: 6px;
  position: absolute;
  bottom: 5rem; /* Ajusta este valor para cambiar la posición vertical */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Asegúrate de que el z-index sea mayor que el de otros elementos */
  &:hover {
    filter: brightness(1.1);
  }
`;

const getCategoryByCode = (title) => {
  if (typeof title !== 'string') {
    return 'Categoría Desconocida';
  }

  const categoryMapping = {
    '4': 'Parcela',
    '5': 'Local',
    '1': 'Casa',
    '3': 'Oficina',
    '2': 'Departamento'
  };

  const digits = title.match(/\d/g);

  const foundCategory = digits ? digits.find(digit => categoryMapping[digit]) : null;

  return categoryMapping[foundCategory] || 'Categoría Desconocida';
};

export default ({
  mainImage,
  title,
  valueUf,
  value,
  currency,
  code,
  ubication,
  characteristics,
  _id,
  operation
}) => {
  const state = useContext(context);

  return (
    <AniLinkCustom to={`/property?id=${_id}`} duration={.5}>
      <Card>
        <Image src={mainImage} />
        <InfoCont>
          <TitleCont>
            <Title>{title}</Title>
            <Price>
                    CLP {formatNumber(value)}
                </Price>
                <Price>
                    UF {formatNumber(valueUf)}
                </Price>
            <Code>
              <strong>{operation} - </strong>
              {code}
            </Code>
          </TitleCont>
          <CharsList>
            <CharItemUbi>
              <Site />
              <span>{ubication.commune}</span>
            </CharItemUbi>
            {characteristics
              .filter(char => (
                char.name === "Superficie total" ||
                char.name === "Superficie útil" ||
                char.name === "Dormitorios" ||
                char.name === "Baños" ||
                char.name === "Estacionamientos"
              ))
              .map((char, index) => (
                <CharItem key={index}>
                  {char.name === "Superficie total" && <Surface />}
                  {char.name === "Superficie útil" && <Surface />}
                  {char.name === "Dormitorios" && <Rooms />}
                  {char.name === "Baños" && <Bath />}
                  {char.name === "Estacionamientos" && <Parking />}
                  <span>
                    {char.name} {char.value} {char.name === "Superficie total" && "mt2" || char.name === "Superficie útil" && "mt2"}
                  </span>
                </CharItem>
              ))}
          </CharsList>
        </InfoCont>
      </Card>
    </AniLinkCustom>
  );
};
